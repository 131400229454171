import { useEffect, useState } from 'react';

interface UseThemedParams<T> {
  light: T;
  dark: T;
}

export const useThemed = <T>({ light, dark }: UseThemedParams<T>) => {
  const [value, setValue] = useState<T>(light);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setValue(dark);
    }

    const listener = (ev: MediaQueryListEvent) => {
      if (ev.matches) {
        setValue(dark);
      } else {
        setValue(light);
      }
    };
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener);
    };
  }, [setValue, light, dark]);

  return value;
};
